//Extended Stepy Button Styles
.stepyBtn-style
{
  font-size: 13px;
}

.stepyBtn-back-style
{
  background-color: #FFFFFF;
  font-size: 13px;
}

//Comprehensive Pages

//Interview
.selectStyle
{
  background-color: transparent;
  font-size: 13px;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}


//Basement
.addImage, .addCustom
{
  background-color: transparent;
  font-size: 13px;
}

//Responsive

@media only screen and (max-width: 1032px) {
  .addImage, .addCustom
  {
    font-size: 11px!important;
  }

  .selectStyle
  {
    width: 115px;
    height: 115px;
  }
}